import Home from "./Home"
import Projects from "./Projects"
import Skills from "./Skills"
import Contact from "./Contact"


const Body = () => {
  return (
    <div>
     <Home/>
     <Projects/>
     <Skills/>
     <Contact/>
    
    </div>
  )
}

export default Body