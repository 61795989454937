import React from 'react'
import { Link } from 'react-router-dom'

const Contact = () => {
  return (
    <div className='bg-[#420fe7]  w-full pt-16 sm:pt-40  xl:px-44 lg:px-36 md:px-32 sm:px-24 vvs:px-10 px-10 flex justify-between flex-col'>
      <div >
        <h1 className='text-white font-Righteous font-extrabold vvs:text-[3.1rem] sm:text-[3rem] md:text-[3.4rem] lg:text-[4.9rem] xl:text-[6.1rem] 1/2xl:text-[6.2rem] 2xl:text-[8rem]  text-[2.9rem] leading-[3.5rem] sm:leading-[3.5rem] md:leading-[4rem] lg:leading-[5rem] xl:leading-[6.5rem] 2xl:leading-[7rem]  cursor-default'>
        Interested ? <br />
        <span className='text-white/70'>Let's Get In Touch !</span>
        </h1>
        <p className='text-white/50  cursor-default  sm:mt-10 mt-8 xl:text-xl xl:max-w-2xl text-base sm:text-lg  1/2xl:text-2xl 1/2xl:max-w-4xl 2xl:text-3xl 2xl:max-w-5xl leading-6'>
        You can contact me through
        <Link to="https://www.linkedin.com/in/souvikghost/" target='_blank'>&nbsp;<span className='text-white font-Righteous'>Linkedin</span></Link>&nbsp;
        Or You can <span>Email</span> me 
        </p>
        <Link to="mailto:soughosh2023@gmail.com" target='_blank'>
          <button className='text-white   rounded-full lg:w-fit hover:bg-[#240658] transition duration-500 ease-in-out  font-medium border-[1px] focus:outline-none flex items-center justify-center border-white/50 py-3 px-6 text-lg xl:text-xl 2xl:text-2xl my-10 w-full'>
            Email

          </button> 
        </Link>
       
       
      </div>
     
     
    </div>
  )
}

export default Contact