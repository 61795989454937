
const YouTubeCloneModal = ({setShowYouTubeModal}) => {
   
    return (
       
          <div
            tabIndex="-1"
            className="fixed top-0 left-0 right-0 z-50 block w-full p-4 overflow-x-hidden overflow-y-auto  md:top-[15%]  lg:top-[15%] lg:left-[15%] xl:top-[25%] xl:left-[20%]"
          >
            <div className="relative w-full max-w-4xl max-h-full">
              <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                  <h3 className="text-xl font-medium text-gray-900 dark:text-white">
                  YouTube Clone
                  </h3>
                  <button
                    type="button"
                    onClick={() => setShowYouTubeModal(false)}
                    className="text-gray-400 bg-transparent  hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    data-modal-hide="large-modal"
                  >
                    <svg
                      className="w-3 h-3"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 14 14"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                      />
                    </svg>
                    <span className="sr-only">Close modal</span>
                  </button>
                </div>
                <div className="p-4 md:p-5 space-y-4">
                  <p className="text-black font-semibold">
                    Used ReactJS, TailwindCSS , Redux
                  </p>
                  <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                  a video streaming app where users can see videos just like YouTube
                  </p>
                  <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                    <span className="text-black">Some Key Point -</span>
                    <br />
                    • used YouTube’s live API to fetch the Video Data and Comments Data <br />
                    • store that Data to Redux <br />
                    • Subscribe to the Store <br />
                    • implemented Live-Chat functionality [ API polling ]<br />
                    • reduced API calling by 500ms in the search bar [ debouncing ]
                    <br />
                    • implemented Memoization
                    <br />
                  </p>
                </div>
                <div className="flex items-center p-4 md:p-5 space-x-3 rtl:space-x-reverse border-t border-gray-200 rounded-b dark:border-gray-600">
                  <button
                    onClick={() => setShowYouTubeModal(false)}
                    type="button"
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  >
                    Understand
                  </button>
                </div>
              </div>
            </div>
          </div>
       
     
  )
}

export default YouTubeCloneModal