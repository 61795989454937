import GithubSvgLogo from "../Image/github SVG.svg";
import LinkedinSvgLogo from "../Image/linkedin SVG.svg";
import GmailSvgLogo from "../Image/Gmail SVG.svg";
import MyPicture from "../Image/Untitled design (1).png"
import { Link } from "react-router-dom";
const Home = () => {
  return (
    <>
    <section className="w-full cursor-default bg-[#420fe7] xl:px-44 lg:px-40 sm:pt-48 sm:pb-0 pt-28 md:px-32 sm:px-28 vvs:px-12 px-10">
      <div className="hero-container flex flex-col">
        <div className="flex justify-between items-center">
          <div className="mylogo" aria-label="Hey, click me">
            <img src={MyPicture} alt="" className="rounded-full !z-10 lg:h-20 lg:w-20 md:h-16 md:w-16 vvs:h-14 vvs:w-14 w-12 h-12 hover:scale-[1.05] hover:shadow-custom1 hover:-rotate-12 transition-all duration-300 ease-in-out cursor-pointer"/>
          </div>
          <div className="flex justify-between items-center space-x-2">
            <div className="h-[1px] md:w-10 sm:w-7 vvs:w-5 w-4 bg-white"></div>
            <p className="text-white  font-medium hover:text-[#EEA1BE] transition-colors duration-300 ease-in-out capitalize vss:text-sm md:text-base xl:text-lg text-xs">Available for work</p>
          </div>
        </div>
        <div>
          <div className="mt-10 mb-8">
            <h1 className="!z-10 2xl:text-[6.5rem] font-Righteous xl:text-[6.2rem] lg:text-[5rem] md:text-[3.5rem] sm:text-[3rem] vvs:text-[2rem] text-[2.2rem] tracking-normal xl:leading-[120px] md:leading-[80px] lg:leading-[100px] sm:leading-[80px] vvs:leading-[60px] leading-[40px]">
              <span className="inline-block z-30   text-white">Hello&nbsp;</span>
              <span className="inline-block z-30  text-white/70  2xl:text-[6.5rem] xl:text-[6.2rem] lg:text-[5rem] md:text-[3.5rem]">
                I'm </span>
              <span className="inline-block z-30   text-white"> &nbsp;Souvik</span>


             


            </h1>

            <h2 className="text-white !z-10 font-Righteous  2xl:text-[5.5rem] xl:text-[5rem] lg:text-[3.5rem] md:text-[2.6rem] sm:text-[2.2rem] vvs:text-[1.5rem] text-[1.4rem] tracking-normal 2xl:leading-[120px] lg:leading-[100px] md:leading-[70px] sm:leading-[50px] leading-[33px]">
            <span className="inline-block z-30 opacity: 1; transform: none;">- trying &nbsp;to &nbsp;</span>
            <span className="inline-block z-30 opacity: 1; transform: none;">become &nbsp;</span>
            <span className="inline-block z-30 opacity: 1; transform: none;">a &nbsp;</span>
            <span className="inline-block z-30 opacity: 1; transform: none;">great &nbsp;</span>
            <span className="inline-block z-30 opacity: 1; transform: none;">web &nbsp;Dev</span>
            </h2>
          </div>
          <div className="opacity: 1;">
            <div className="w-full flex md:flex-row flex-col justify-between items-center ">
            <p className="text-white/60  xl:text-[1.3rem] 2xl:text-[1.5rem] lg:text-base md:text-sm text-xs xl:leading-8 2xl:leading-9 lg:leading-6 leading-5 mb-6 md:mb-0">My Name is Souvik Ghosh. After completing 10th and 12th I took admission in B.Tech. I completed my engineering in July 2022. I have knowledge of ReactJS and back-end stuff like ASP.Net along with MS SQL Server, which helps me to build Creative and Interactive single-page/dynamic web apps. Trying to learn something new day by day.  As a dev, learning is a never-ending process. Thanks for your presence on this webpage 🙏</p>
            </div>

          </div>
          <div className="z-[10] flex justify-between w-full items-center my-14">
        <Link to="mailto:soughosh2023@gmail.com" >
          <span className="icon-wrapper rounded-full  sm:absolute sm:top-[65%] sm:left-[3%] 2xl:h-[6rem] 2xl:w-[6rem] xl:h-[5.6rem] xl:w-[5.6rem] lg:h-[5.2rem] lg:w-[5.2rem] md:h-[4.8rem] md:w-[4.8rem] vvs:h-[4.4rem] vvs:w-[4.4rem] h-[3.5rem] w-[3.5rem] bg-[#ffffff]/70 flex justify-center items-center">
            <span className="icon-container rounded-full  2xl:h-[5rem] 2xl:w-[5rem] xl:h-[4.6rem] xl:w-[4.6rem] lg:h-[4.2rem] lg:w-[4.2rem] md:h-[3.8rem] md:w-[3.8rem] vvs:h-[3.4rem] vvs:w-[3.4rem] h-[2.5rem] w-[2.5rem] bg-[#ffffff]/90 flex justify-center items-center">
              <img
                src={GmailSvgLogo}
                alt="GithubLogo"
                className="sm:h-8 sm:w-8 h-5 w-5 opacity: 1; transform: translate(0px, 0px);"
              />
            </span>
          </span>
        </Link>
        <Link to="https://github.com/souvikghost" target='_blank'>
          <span className="icon-wrapper rounded-full sm:absolute xl:top-[8.2%] xl:left-[50%] lg:top-[9%] sm:top-[13%] sm:left-[45%] md:top-[11%] md:left-[43%] lg:left-[47%] 2xl:h-[6rem] 2xl:w-[6rem] xl:h-[5.6rem] xl:w-[5.6rem] lg:h-[5.2rem] lg:w-[5.2rem] md:h-[4.8rem] md:w-[4.8rem] vvs:h-[4.4rem] vvs:w-[4.4rem] h-[3.5rem] w-[3.5rem] bg-[#ffffff]/70 flex justify-center items-center">
            <span className="icon-container rounded-full  2xl:h-[5rem] 2xl:w-[5rem] xl:h-[4.6rem] xl:w-[4.6rem] lg:h-[4.2rem] lg:w-[4.2rem] md:h-[3.8rem] md:w-[3.8rem] vvs:h-[3.4rem] vvs:w-[3.4rem] h-[2.5rem] w-[2.5rem] bg-[#ffffff]/90 flex justify-center items-center">
              <img
                src={GithubSvgLogo}
                alt="GithubLogo"
                className="sm:h-10 sm:w-10 h-6 w-6 opacity: 1; transform: translate(0px, 0px);"
              />
            </span>
          </span>
        </Link>
        <Link to="https://www.linkedin.com/in/souvikghost/" target='_blank'>
          <span className="icon-wrapper rounded-full sm:absolute sm:top-[40%] sm:right-[5%]  2xl:h-[6rem] 2xl:w-[6rem] xl:h-[5.6rem] xl:w-[5.6rem] lg:h-[5.2rem] lg:w-[5.2rem] md:h-[4.8rem] md:w-[4.8rem] vvs:h-[4.4rem] vvs:w-[4.4rem] h-[3.5rem] w-[3.5rem] bg-[#ffffff]/70 flex justify-center items-center">
            <span className="icon-container rounded-full 2xl:h-[5rem] 2xl:w-[5rem] xl:h-[4.6rem] xl:w-[4.6rem] lg:h-[4.2rem] lg:w-[4.2rem] md:h-[3.8rem] md:w-[3.8rem] vvs:h-[3.4rem] vvs:w-[3.4rem] h-[2.5rem] w-[2.5rem] bg-[#ffffff]/90 flex justify-center items-center">
              <img
                src={LinkedinSvgLogo}
                alt="GithubLogo"
                className="sm:h-8 sm:w-8 h-5 w-5 opacity: 1; transform: translate(0px, 0px);"
              />
            </span>
          </span>
        </Link>
      </div>
        </div>

      </div>

    </section>

    </>
  );
};

export default Home;
