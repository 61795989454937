import giticon from "../Image/github SVG.svg";
import RightArrow from "../Image/Right Arrow.svg";
import youtubeClone from "../Image/YouTube Clone.png";
import foodOrderingApp from "../Image/Food Ordering App.png";
import oldWebsite from "../Image/My Old Website.png";
import netflixGPT from "../Image/NetFflixGPT.png";
import library from "../Image/Library Management.png";
import NetflixGPTModal from "./NetflixGPTModal";
import { useState } from "react";
import FoodOrderinGModal from "./FoodOrderinGModal";
import YouTubeCloneModal from "./YouTubeCloneModal";
import LibraryModal from "./LibraryModal";
import { Link } from "react-router-dom";
const Projects = () => {
  const [showModal , setShowModal]=useState(false);
  const [showFoodModal,setShowFoodModal]=useState(false);
  const [showYouTubeModal , setShowYouTubeModal]=useState(false);
  const [showLibraryModal , setShowLibraryModal]=useState(false);
  const handleModalChange=()=>{
      setShowModal(!showModal)
  }
  const handleFoodModalChange=()=>{
    setShowFoodModal(!showFoodModal)
  }
  const handleYouTubeModalChange=()=>{
    setShowYouTubeModal(!showYouTubeModal)
  }
  const handleLibraryModalChange=()=>{
    setShowLibraryModal(!showLibraryModal)
  }

















  return (
    <div className="w-full bg-[#420fe7]  pb-30 xl:px-44 lg:px-36 md:px-32 sm:px-28 vvs:px-12 px-10">
      <h1 className="text-white/70 font-Righteous font-semibold z-40 lg:mb-32 mb-20 vvs:text-[3rem] sm:text-[5.2rem] md:text-[6.4rem] lg:text-[7.5rem] xl:text-[9.2rem] 2xl:text-[10.8rem]  text-[3.5rem] leading-[3.5rem] sm:leading-[5.5rem] md:leading-[6rem] lg:leading-[7rem] xl:leading-[9rem] 2xl:leading-[10rem]  cursor-default opacity: 1; transform: translate(0px, 0px);">
        Projects
      </h1>

      <div className="sm:space-y-40 space-y-32 xl:space-y-[35vh] z-30">
        {/* 1 */}
        <div className="container lg:space-x-2 flex flex-col lg:flex-row lg:justify-between lg:items-center items-start  visibility: visible;">
          <div className="lg:h-[400px] 2xl:h-[500px] h-[250px] sm:h-[300px] md:h-[350px] w-full relative overflow-hidden img-wrapper">
            <img
              src={netflixGPT}
              alt=""
              className="absolute w-full object-cover rounded-2xl h-full img transform: translate(0px, 0px); "
            />
          </div>
          <div className="text-container text-white lg:space-y-4 space-y-2 md:space-y-3 sm:space-y-3">
            <h1 className="hidden-text font-bold  cursor-default lg:text-4xl text-2xl sm:text-3xl 2xl:text-5xl lg:mt-0 mt-5">
              <div className="content-line flex lg:justify-end items-center m-0 lg:h-[52px] 2xl:h-[60px] overflow-hidden bg-[#420fe7] ">
                <div className="font-Righteous">NetflixGPT</div>
              </div>
            </h1>
            <p className="font-normal cursor-default  text-white/70 xl:text-2xl  sm:text-xl 2xl:text-3xl vvs:text-base text-sm lg:text-right opacity: 1; transform: translate(0px, 0px);">
              it’s an OTT platform where users can stream and search for any
              movies.
              <button onClick={handleModalChange} className="text-white border border-gray-600  rounded-full  hover:bg-[#240658] transition duration-500 ease-in-out text-md md:text-lg  focus:outline-none   border-white/50 px-3 py-2 ">Read More</button>
              
            </p>
            {showModal && <> <NetflixGPTModal showModal={showModal} setShowModal={setShowModal}/> </>}
            <div className="btn-row flex justify-start space-x-6 items-center lg:justify-end opacity: 1; transform: translate(0px, 0px);">
              <div className="cursor-pointer ">
                <Link to="https://github.com/souvikghost/NetflixGPT" target='_blank'>
                <img src={giticon} alt="" className="w-8" />
                </Link>
              </div>
              <div className="font-extrabold text-2xl cursor-pointer hover:-rotate-[45deg] hover:text-white transition duration-200 ease-in-out">
              <Link to="https://github.com/souvikghost/NetflixGPT" target='_blank'>
                <img src={RightArrow} alt="" className="" />
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/*  2  */}
        <div className="container lg:space-x-2 flex flex-col lg:flex-row lg:justify-between lg:items-center items-start  visibility: visible;">
          <div className="lg:h-[400px] 2xl:h-[500px] h-[250px] sm:h-[300px] md:h-[350px] w-full relative overflow-hidden img-wrapper">
            <img
              src={foodOrderingApp}
              alt=""
              className="absolute w-full object-cover rounded-2xl h-full img transform: translate(0px, 0px);"
            />
          </div>
          <div className="text-container text-white lg:space-y-4 space-y-2 md:space-y-3 sm:space-y-3">
            <h1 className="hidden-text font-bold  cursor-default lg:text-4xl text-2xl sm:text-3xl 2xl:text-5xl lg:mt-0 mt-5">
              <div className="content-line flex lg:justify-end items-center m-0 lg:h-[52px] 2xl:h-[60px] overflow-hidden bg-[#420fe7] ">
                <div className="font-Righteous">Food Ordering App</div>
              </div>
            </h1>
            <p className="font-normal cursor-default  text-white/70 xl:text-2xl  sm:text-xl 2xl:text-3xl vvs:text-base text-sm lg:text-right opacity: 1; transform: translate(0px, 0px);">
            a food ordering app where user can order foods from any restaurants <br />
            <button onClick={handleFoodModalChange} className="text-white  border border-gray-600 rounded-full  hover:bg-[#240658] transition duration-500 ease-in-out text-md md:text-lg  focus:outline-none   border-white/50 px-3 py-2 ">Read More</button>
             
            </p>
            {showFoodModal && <> <FoodOrderinGModal showFoodModal={showFoodModal} setShowFoodModal={setShowFoodModal}/></>}
            <div className="btn-row flex justify-start space-x-6 items-center lg:justify-end opacity: 1; transform: translate(0px, 0px);">
              <div className="cursor-pointer ">
                <Link to="https://github.com/souvikghost/Food-Ordering-App" target='_blank'>
                <img src={giticon} alt="" className="w-8" />
                </Link>
              </div>
              <div className="font-extrabold text-2xl cursor-pointer hover:-rotate-[45deg] hover:text-white transition duration-200 ease-in-out">
              <Link to="https://github.com/souvikghost/Food-Ordering-App" target='_blank'>
                <img src={RightArrow} alt="" className="" />
                </Link>

              </div>
            </div>
          </div>
        </div>
        {/*  3  */}
        <div className="container lg:space-x-2 flex flex-col lg:flex-row lg:justify-between lg:items-center items-start  visibility: visible;">
          <div className="lg:h-[400px] 2xl:h-[500px] h-[250px] sm:h-[300px] md:h-[350px] w-full relative overflow-hidden img-wrapper">
            <img
              src={youtubeClone}
              alt=""
              className="absolute w-full object-cover rounded-2xl h-full img transform: translate(0px, 0px);"
            />
          </div>
          <div className="text-container text-white lg:space-y-4 space-y-2 md:space-y-3 sm:space-y-3">
            <h1 className="hidden-text font-bold  cursor-default lg:text-4xl text-2xl sm:text-3xl 2xl:text-5xl lg:mt-0 mt-5">
              <div className="content-line flex lg:justify-end items-center m-0 lg:h-[52px] 2xl:h-[60px] overflow-hidden bg-[#420fe7] ">
                <div className="font-Righteous">YouTube Clone</div>
              </div>
            </h1>
            <p className="font-normal cursor-default  text-white/70 xl:text-2xl  sm:text-xl 2xl:text-3xl vvs:text-base text-sm lg:text-right opacity: 1; transform: translate(0px, 0px);">
            a video streaming app where users can see videos just like YouTube <br />

              <button onClick={handleYouTubeModalChange} className="text-white border border-gray-600  rounded-full  hover:bg-[#240658] transition duration-500 ease-in-out text-md md:text-lg  focus:outline-none   border-white/50 px-3 py-2 ">Read More</button>
            </p>
            {showYouTubeModal && <> <YouTubeCloneModal showYouTubeModal={showYouTubeModal} setShowYouTubeModal={setShowYouTubeModal}/></>}
            <div className="btn-row flex justify-start space-x-6 items-center lg:justify-end opacity: 1; transform: translate(0px, 0px);">
              <div className="cursor-pointer ">
                <Link to="https://github.com/souvikghost/YouTube-Clone" target='_blank'>
                <img src={giticon} alt="" className="w-8" />
                </Link>
              </div>
              <div className="font-extrabold text-2xl cursor-pointer hover:-rotate-[45deg] hover:text-white transition duration-200 ease-in-out">
              <Link to="https://github.com/souvikghost/YouTube-Clone" target='_blank'>
                
                <img src={RightArrow} alt="" className="" />
                </Link>

              </div>
            </div>
          </div>
        </div>
        {/*  4  */}
        <div className="container lg:space-x-2 flex flex-col lg:flex-row lg:justify-between lg:items-center items-start  visibility: visible;">
          <div className="lg:h-[400px] 2xl:h-[500px] h-[250px] sm:h-[300px] md:h-[350px] w-full relative overflow-hidden img-wrapper">
            <img
              src={oldWebsite}
              alt=""
              className="absolute w-full object-cover h-full rounded-2xl transform: translate(0px, 0px);"
            />
          </div>
          <div className="text-container text-white lg:space-y-4 space-y-2 md:space-y-3 sm:space-y-3">
            <h1 className="hidden-text font-bold  cursor-default lg:text-4xl text-2xl sm:text-3xl 2xl:text-5xl lg:mt-0 mt-5">
              <div className="content-line flex lg:justify-end items-center m-0 lg:h-[52px] 2xl:h-[60px] overflow-hidden bg-[#420fe7] ">
                <div className="font-Righteous">Personal Website(Old)</div>
              </div>
            </h1>
            <p className="font-normal cursor-default  text-white/70 xl:text-2xl  sm:text-xl 2xl:text-3xl vvs:text-base text-sm lg:text-right opacity: 1; transform: translate(0px, 0px);">
              This is a website which i built using HTML , CSS and JS and hosted it 
            </p>
            <div className="btn-row flex justify-start space-x-6 items-center lg:justify-end opacity: 1; transform: translate(0px, 0px);">
             
              <div className="font-extrabold text-2xl cursor-pointer hover:-rotate-[45deg]  hover:text-white transition duration-200 ease-in-out">
                <Link to="https://souvikghost.netlify.app/" target="_blank">
                
                <img src={RightArrow} alt="" className="" />
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* 5 */}
        <div className="container lg:space-x-2 flex flex-col lg:flex-row lg:justify-between lg:items-center items-start  visibility: visible;">
          <div className="lg:h-[400px] 2xl:h-[500px] h-[250px] sm:h-[300px] md:h-[350px] w-full relative overflow-hidden img-wrapper">
            <img
              src={library}
              alt=""
              className="absolute w-full object-cover h-full rounded-2xl transform: translate(0px, 0px);"
            />
          </div>
          <div className="text-container text-white lg:space-y-4 space-y-2 md:space-y-3 sm:space-y-3">
            <h1 className="hidden-text font-bold  cursor-default lg:text-4xl text-2xl sm:text-3xl 2xl:text-5xl lg:mt-0 mt-5">
              <div className="content-line flex lg:justify-end items-center m-0 lg:h-[52px] 2xl:h-[60px] overflow-hidden bg-[#420fe7] ">
                <div className="font-Righteous">Library-Management</div>
              </div>
            </h1>
            <p className="font-normal cursor-default  text-white/70 xl:text-2xl  sm:text-xl 2xl:text-3xl vvs:text-base text-sm lg:text-right opacity: 1; transform: translate(0px, 0px);">
            Library Management System, programmed a platform where all library facilities are managed <br />
            <button onClick={handleLibraryModalChange} className="text-white  border border-gray-600 rounded-full  hover:bg-[#240658] transition duration-500 ease-in-out text-md md:text-lg  focus:outline-none   border-white/50 px-3 py-2 ">Read More</button>
            </p>
            {showLibraryModal && <> <LibraryModal setShowLibraryModal={setShowLibraryModal}/></>}
            <div className="btn-row flex justify-start space-x-6 items-center lg:justify-end opacity: 1; transform: translate(0px, 0px);">
              <div className="cursor-pointer ">
                <Link to="https://github.com/souvikghost/Library-Management-System" target='_blank'>
                
                <img
                  src={giticon}
                  alt=""
                  className="w-8  hover:-rotate-[180deg] hover: transition duration-200 ease-in-out"
                />
                </Link>
              </div>
              <div className="font-extrabold text-2xl cursor-pointer hover:-rotate-[45deg]  hover:text-white transition duration-200 ease-in-out">
              <Link to="https://github.com/souvikghost/Library-Management-System" target='_blank'>
               
                <img src={RightArrow} alt="" className="" />
                </Link>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects;
