import './App.css';
import Body from './components/Body';


function App() {

  
  return (
    <div className='App'>
      <Body/>

    </div>
  );
}

export default App;
