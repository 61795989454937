import reactSvgIcon from "../Image/react svg.svg"
import jsSvgIcon from "../Image/js svg.svg"
import firebaseSvgIcon from "../Image/firebase svg.svg"
import dotnetSvgIcon from "../Image/dotnet svg.svg"
import sqlServerSvgIcon from "../Image/microsoft svg.svg"
import jquerySvgIcon from "../Image/jquery svg.svg"
import tailwindCssIcon from "../Image/tailwindcss svg.svg"
import bootstrapSvgIcon from "../Image/bootstrap svg.svg"
const Skills = () => {
  return (
    <div className="bg-[#420fe7] pt-20">
      
    <div className='h-[calc(100vh_+_20rem)] w-full  relative overflow-hidden '>
      
    <h1 className="2xl:text-[8rem] font-Righteous font-semibold xl:text-[7rem] lg:text-[6rem] md:text-[5rem] sm:text-[4rem] vvs:text-[3rem] text-[2.5rem] cursor-default absolute text-center sm:bottom-[40px] xl:bottom-[40px] bottom-[100px] opacity-100 leading-normal text-white/60">
      Languages and Frameworks
      </h1>
      <img src={reactSvgIcon} alt="" className="2xl:h-28 2xl:w-28 xl:h-24 xl:w-24 lg:h-20 lg:w-20 md:h-20 md:w-20 sm:h-16 sm:w-16 vvs:h-20 vvs:w-20 w-12 h-12 absolute 2xl:top-[260px] 2xl:left-[300px]  1xl:top-[240px] 1xl:left-[300px] 1/2xl:top-[220px] 1/2xl:left-[300px]   xl:top-[260px] xl:left-[300px] lg:top-[200px] lg:left-[290px] md:top-[200px] md:left-[200px] sm:top-[300px] sm:left-[180px] vvs:top-[350px] vvs:left-[150px] top-[340px] left-[60px]  opacity-1 rotate-[10deg] left: 200px; top: 200px; transform: rotate(10.0001deg); opacity: 1;" />
      <img src={tailwindCssIcon} alt="" className="tech 2xl:h-28 2xl:w-28 xl:h-24 xl:w-24  lg:h-20 lg:w-20 md:h-20 md:w-20 sm:h-16 sm:w-16 vvs:h-20 vvs:w-20 w-12 h-12 absolute 2xl:top-[460px] 2xl:left-[400px] 1xl:top-[400px] 1xl:left-[350px] 1/2xl:top-[400px] 1/2xl:left-[360px] xl:top-[420px] xl:left-[360px] lg:top-[360px] lg:left-[320px] md:top-[340px] md:left-[230px] sm:top-[440px] sm:left-[100px] vvs:top-[400px] vvs:left-[40px] top-[490px] left-[40px] opacity-1 rotate-[-10deg] left: 230px; top: 340px; transform: rotate(-10.0001deg); opacity: 1;"/>
      <img src={jsSvgIcon} alt="" className="tech 2xl:h-28 2xl:w-28 xl:h-24 xl:w-24  lg:h-20 lg:w-20 md:h-20 md:w-20 sm:h-16 sm:w-16 vvs:h-14 vvs:w-14 w-12 h-12 absolute 2xl:top-[300px] 2xl:right-[850px] 1xl:top-[400px] 1xl:right-[750px] 1/2xl:top-[270px] 1/2xl:right-[680px] xl:top-[510px] xl:right-[650px] lg:top-[410px] lg:right-[450px] md:top-[450px] md:right-[350px] sm:top-[450px] sm:right-[350px] vvs:top-[490px] vvs:right-[100px] top-[430px] right-[150px] opacity-1 rotate-[4deg] right: 350px; top: 450px; transform: rotate(4.0002deg); opacity: 1;"/>
      <img src={firebaseSvgIcon} alt=""  className="tech 2xl:h-28 2xl:w-28 xl:h-24 xl:w-24  lg:h-20 lg:w-20 md:h-20 md:w-20 sm:h-16 sm:w-16 vvs:h-14 vvs:w-14 w-12 h-12 absolute 2xl:right-[880px] 2xl:bottom-[950px] 1xl:right-[690px] 1xl:bottom-[850px] 1/2xl:right-[680px] 1/2xl:bottom-[950px] xl:right-[680px] xl:bottom-[950px] lg:right-[480px] lg:bottom-[860px] md:right-[340px] md:bottom-[820px] sm:right-[340px] sm:bottom-[820px] vvs:right-[180px] vvs:bottom-[900px] right-[100px] bottom-[710px] opacity-1 rotate-[-50deg] right: 340px; bottom: 820px;"/>
      <img src={dotnetSvgIcon} alt="" className="tech 2xl:h-28 2xl:w-28 xl:h-24 xl:w-24  lg:h-20 lg:w-20 md:h-20 md:w-20 sm:h-16 sm:w-16 vvs:h-24 vvs:w-24 w-12 h-12 absolute 2xl:right-[620px] 2xl:top-[350px]  xl:right-[520px] xl:top-[350px] lg:right-[400px] lg:top-[250px] md:right-[280px] md:top-[300px] sm:right-[280px] sm:top-[300px] vvs:right-[60px] vvs:top-[170px] right-[80px] top-[220px] opacity-1 rotate-[-48deg] right: 280px; top: 300px; transform: rotate(-47.9998deg) skew(0.000383383deg, 0deg); opacity: 1;"/>
      <img src={sqlServerSvgIcon} alt="" className="tech 2xl:h-28 2xl:w-28 xl:h-24 xl:w-24  lg:h-20 lg:w-20 md:h-20 md:w-20 sm:h-16 sm:w-16 vvs:h-24 vvs:w-24 w-12 h-12 absolute  2xl:top-[140px] 2xl:right-[620px] xl:top-[440px] xl:right-[320px] lg:top-[480px] lg:right-[210px] md:top-[480px] md:right-[210px] sm:top-[400px] sm:right-[140px] vvs:top-[220px] vvs:right-[240px] top-[220px] right-[240px] opacity-1 rotate-[18deg] top: 480px; transform: rotate(18.0001deg); opacity: 1;"/>
      <img src={jquerySvgIcon} alt="" className="tech 2xl:h-28 2xl:w-28 xl:h-24 xl:w-24  lg:h-20 lg:w-20 md:h-20 md:w-20 sm:h-16 sm:w-16 vvs:h-24 vvs:w-24 w-12 h-12 absolute 2xl:right-[420px] 2xl:top-[240px] xl:right-[420px] xl:top-[240px] lg:right-[320px] lg:top-[360px] md:right-[250px] md:top-[400px] sm:right-[250px] sm:top-[380px] vvs:right-[25px] vvs:top-[300px] right-[40px] top-[420px] opacity-1 rotate-[18deg] right: 250px; top: 400px; transform: rotate(18.0001deg); opacity: 1;"/>
      <img src={bootstrapSvgIcon} alt="" className="tech 2xl:h-28 2xl:w-28 xl:h-24 xl:w-24  lg:h-20 lg:w-20 md:h-20 md:w-20 sm:h-16 sm:w-16 vvs:h-20 vvs:w-20 w-12 h-12 absolute 2xl:left-[280px] 2xl:bottom-[540px] 1xl:left-[290px] 1xl:bottom-[480px] 1/2xl:left-[280px] 1/2xl:bottom-[450px] xl:left-[280px] xl:bottom-[480px] lg:left-[280px] lg:bottom-[420px] md:left-[240px] md:bottom-[420px] sm:left-[140px] sm:bottom-[420px] vvs:left-[90px] vvs:bottom-[320px] left-[40px] bottom-[400px] opacity-1 rotate-45 left: 240px; bottom: 420px; transform: rotate(45deg); opacity: 1;"/>

    </div>
    </div>
  )
}

export default Skills